import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box, Text } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <img width="960" alt="An image showing a branch name component." src="https://user-images.githubusercontent.com/586552/207118150-306e0679-f77b-4668-8700-3da506256ad1.png" />
    <h2>{`Usage`}</h2>
    <p>{`Use this component to represent a branch name label and link to its tree.`}</p>
    <h2>{`Options`}</h2>
    <h3>{`Icon`}</h3>
    <p>{`Include the branch icon when using the component in isolation and in places where users can’t easily determine whether the label represents a branch from the surrounding context.`}</p>
    <img width="960" alt="An image showing a branch name component with a branch icon." src="https://user-images.githubusercontent.com/586552/207118155-5660c7c8-32f0-4eee-84c6-ce2b5c98b3ba.png" />
    <h2>{`As a link`}</h2>
    <p>{`Branch name is rendered as a link by default. You can also represent the branch label in plain text.`}</p>
    <img width="960" alt="An image showing a branch name component as a link and a branch name component as plain text." src="https://user-images.githubusercontent.com/586552/204637507-08c61b68-c474-4d64-9d64-91bf46fcff42.png" />
    <h2>{`Accessibility`}</h2>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="BranchName" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      